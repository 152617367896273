import { removeEmptyField } from "../utilities/objectUtils"

const buildPageName = (pageType, categories) => {
  switch(pageType) {
    case "category": return `rea:${categories[0]}:category`
    case "post": return `rea:${categories[0]}:post`
    case "author": return "rea:authordetails"
    case "search": return `rea:${categories[0]}:search`
    case "tag": return "rea:tag"
    default: return ""
  }
}

const buildSiteSubSection = (pageType, categories, tagName) => {
  if(!categories || categories.length < 2) return ""

  switch(pageType) {
    case "category": return `rea:${categories[0]}:${categories[1]}`
    case "post": return `rea:${categories[0]}:${categories[1]}`
    case "author": return "rea:authordetails"
    case "search": return `rea:${categories[0]}:${categories[1]}`
    case "tag": return `rea:${tagName}`
    default: return `rea:${categories[0]}:${categories[1]}`
  }
}

const buildSiteSubSubSection = (pageType, categories) => {
  if(!categories || categories.length < 3) return ""

  switch(pageType) {
    case "category": return `rea:${categories[0]}:${categories[1]}:${categories[2]}`
    case "post": return `rea:${categories[0]}:${categories[1]}:${categories[2]}`
    case "author": return ""
    case "search": return `rea:${categories[0]}:${categories[1]}:${categories[2]}`
    case "tag": return ""
    default: return `rea:${categories[0]}:${categories[1]}:${categories[2]}`
  }
}

const buildSiteSubSubSubSection = (pageType, categories) => {
  if(!categories || categories.length < 4) return ""

  switch(pageType) {
    case "category": return `rea:${categories[0]}:${categories[1]}:${categories[2]}:${categories[3]}`
    case "post": return `rea:${categories[0]}:${categories[1]}:${categories[2]}:${categories[3]}`
    case "author": return ""
    case "search": return `rea:${categories[0]}:${categories[1]}:${categories[2]}:${categories[3]}`
    case "tag": return ""
    default: return `rea:${categories[0]}:${categories[1]}:${categories[2]}:${categories[3]}`
  }
}

const buildVideoAndGalleryFlag = (page) => {
  const videoExist = page.normalVideo
  const imageGalleryExist = page.imageGallery
  if (videoExist && imageGalleryExist) return "gal-vid"
  if (videoExist && !imageGalleryExist) return "vid"
  if (!videoExist && imageGalleryExist) return "gal"
}

const getArticleName = (page) => {
  const { sourceName, articleSlug } = page

  const defaultArticleName = sourceName
    ? `${sourceName}:${articleSlug}`
    : articleSlug
  const videoGalleryFlag = buildVideoAndGalleryFlag(page)
  return videoGalleryFlag
    ? `${videoGalleryFlag}:${defaultArticleName}`
    : defaultArticleName
}

const getClickThroughSource = (searchParams) => {
  const sourcePage =
    searchParams &&
    (searchParams.get("sourcePage") || searchParams.get("page"))
  const sourceElement =
    searchParams &&
    (searchParams.get("sourceElement") || searchParams.get("element"))
  const sourceType = searchParams && searchParams.get("sourceType")
  return {
    clickThroughSourcePage: sourcePage,
    clickThroughSourceElement: sourceElement,
    clickThroughSourceType: sourceType
  }
}

export const pageDataOptionsTransformer = (
  page = {},
  categories = [],
  searchParams = null
) => {
  const { pageType, tagName } = page

  return removeEmptyField({
    pageNameOverride: buildPageName(pageType, categories),
    pageType: pageType || "",
    siteSection: page.channel || "",
    siteSubSection: buildSiteSubSection(pageType, categories, tagName),
    siteSubSubSection: buildSiteSubSubSection(pageType, categories),
    siteSubSubSubSection: buildSiteSubSubSubSection(pageType, categories),
    articleName: getArticleName(page) || "",
    ...getClickThroughSource(searchParams)
  })
}
