import React, {Component} from "react"

class ReadMoreButton extends Component {
  constructor(props) {
    super(props)
  }

  componentWillMount() {
    this.setState({ expanded: false })
  }

  toggleContent() {
    let postContentElement = document.getElementsByClassName("post-content")[0]

    if (postContentElement) {
      postContentElement.classList.add("post-content--js-read-full")
    }

    this.setState({ expanded: true })
  }

  render() {
    if(this.state.expanded) {
      return null
    }

    return (
      <div>
        <button onClick={ () => { this.toggleContent() } } className="post-content__toggle">Read the whole article</button>
      </div>
    )
  }
}

export default ReadMoreButton
