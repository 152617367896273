import React, {Component} from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"

/**
 * Adapted from https://github.com/svenanders/react-stickydiv
 * Modified and converted to ES6
 */

const buildAnimationFrame = function(handleFunction) {
  var ticking = false
  return (e) => {
    if (!ticking) {
      requestAnimationFrame(() => {
        handleFunction(e)
        ticking = false
      })
      ticking = true
    }
  }
}
class StickyContainer extends Component {
  constructor(props) {
    super(props)
  }

  static defaultProps = {
    offsetTop: 0,
    className: "",
    zIndex: 1
  }

  state = {
    fixed: false
  }

  static anchoredClassName = "anchored"
  static sidebarContainerId = "rui-main-sidebar"
  static tagsContainerClassName = "recommended-video-articles"

  render() {
    let defaultStyle = {
      display: "block",
      position: "relative",
      zIndex: this.props.zIndex,
      transform: "translate3d(0px,0px,0px)"
    }

    switch (this.state.positionStatus) {
      case "fixed":
        Object.assign(defaultStyle, { position: "fixed", top: this.props.offsetTop })
        break
      case "absolute":
        Object.assign(defaultStyle, { position: "absolute", bottom: 0 })
        break
      default:
        Object.assign(defaultStyle, { position: "relative"})
    }

    return (
      <div className={this.props.className} style={defaultStyle}>
        {this.props.children}
      </div>
    )
  }

  componentDidMount() {
    this.scrollInEveryFrame = buildAnimationFrame(this.onScroll.bind(this))
    this.resizeInEveryFrame = buildAnimationFrame(this.onResize.bind(this))
    window.addEventListener("scroll", e => this.scrollInEveryFrame(e), false)
    window.addEventListener("resize", e => this.resizeInEveryFrame(e))
  }

  componentWillUnMount() {
    window.removeEventListener("scroll", e => this.scrollInEveryFrame(e), false)
    window.removeEventListener("resize", e => this.resizeInEveryFrame(e))
  }

  onResize() {
    this.checkPositions()
  }

  onScroll() {
    this.checkPositions()
  }

  checkPositions() {
    const parentContainer = ReactDOM.findDOMNode(this).parentNode
    const sidebarContainer = document.getElementById(StickyContainer.sidebarContainerId)
    const sidebarContainerRect = sidebarContainer.getClientRects()[0]
    const parentContainerRect =  parentContainer.getClientRects()[0]

    if (sidebarContainer && parentContainer) {
      const stickyAdsTopOffset = parentContainerRect.top - this.props.offsetTop
      const stickyAdsBottomOffset = sidebarContainerRect.bottom - parentContainerRect.height - this.props.offsetTop
      if (stickyAdsTopOffset < 0 && stickyAdsBottomOffset >= 0) {
        this.setState({positionStatus: "fixed"})
      } else if(stickyAdsBottomOffset < 0) {
        this.setState({positionStatus: "absolute"})
      } else {
        this.setState({positionStatus: "relative"})
      }
    }
  }

  removeAnchoredClass(parentContainer) {
    parentContainer.className = parentContainer.className.replace(StickyContainer.anchoredClassName,"")
  }

  addAnchoredClass(parentContainer) {
    parentContainer.className = `${parentContainer.className.replace(StickyContainer.anchoredClassName,"").trim()} ${StickyContainer.anchoredClassName}`
  }
}

StickyContainer.propTypes = {
  offsetTop: PropTypes.number,
  zIndex: PropTypes.number,
  className: PropTypes.string
}

export default StickyContainer
