// until we get tree shaking, import these individually to reduce bundle size:
// import { isEqual } from "lodash"
import isEqual from "lodash/isEqual"

class BrightcoveUtil {
  static isPlaylist(videoPlayer) {
    return videoPlayer.el().dataset["playlistId"] !== undefined
  }

  static bindCallbacks(videoPlayer, callbackListByEvent) {
    for (const event in callbackListByEvent) {
      videoPlayer.on(event, () => {
        callbackListByEvent[event].forEach(callback => {
          callback(videoPlayer)
        })
      })
    }
  }

  static hasPlaylistChanged(oldPlaylist, newPlaylist) {
    return !isEqual(oldPlaylist, newPlaylist)
  }
}

export default BrightcoveUtil
