import CookieUtils from "./cookie.utils"

const AppSource = {
  Android: "app_android",
  iPhone: "app_iphone",
  iPad: "app_ipad",
  Mobile: "app_mobile"
}

const AppClient = {
  Android: "android",
  iPhone: "iphone",
  iPad: "ipad",
  OutdatedApp: "outdated_app"
}

const getQueryParamValue = (queryParam) => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  return urlParams.get(queryParam)
}

export const urlInfo = () => {
  const source = getQueryParamValue("source")
  const { client: cookieClient } = CookieUtils.grab(["client"])
  const client = getQueryParamValue("client") || cookieClient
  const isApp =
    Object.values(AppSource).includes(source) ||
    Object.values(AppClient).includes(client)

  return { source, client, isApp }
}

const platformMappedBySource = {
  app_android: "android",
  app_iphone: "iphoneapp",
  app_ipad: "ipadapp",
  app_mobile: "web"
}

const platformMappedByClient = {
  android: "android",
  iphone: "iphoneapp",
  ipad: "ipadapp",
  outdated_app: "outdated_app"
}

export const getDeviceFromSource = (source) => platformMappedBySource[source]

export const getDeviceFromClient = (client) => platformMappedByClient[client]
