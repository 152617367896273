class DomUtils {
  constructor(document) {
    this.document = document
  }

  getVisibilityValue(element) {
    if (element) {
      let computedStyle = this.document.defaultView.getComputedStyle(element)
      return computedStyle.getPropertyValue("visibility")
    }
    return
  }

  getDisplayValue(element) {
    if (element) {
      let computedStyle = this.document.defaultView.getComputedStyle(element)
      return computedStyle.getPropertyValue("display")
    }
    return
  }

  disablePageScroll() {
    if(!this.hasClassName(this.document.documentElement, "disable-scroll")) {
      this.document.documentElement.className += " disable-scroll"
    }
  }

  enablePageScroll() {
    const HtmlElement = this.document.documentElement
    if(this.hasClassName(HtmlElement, "disable-scroll")) {
      HtmlElement.className = HtmlElement.className.replace(" disable-scroll", "")
    }
  }

  hasClassName (element, className) {
    return Array.prototype.slice.call(element.classList).indexOf(className) !== -1
  }

  getVideoIds() {
    let videoElements = Array.prototype.slice.call(this.document.querySelectorAll("video[data-account]"))
    return videoElements.map(el => el.id)
  }

  getImageGalleryTitle(galleryNode) {
    const titleNode = galleryNode.querySelector(".new-image-gallery h3")
    return titleNode ? titleNode.innerText : ""
  }

  getImageGalleryImages(galleryNode) {
    const galleryItemNodes = galleryNode.querySelectorAll(".new-image-gallery .gallery-item")
    return Array.from(galleryItemNodes).map(itemNode => {
      const imageNode = itemNode.querySelector("img")
      const captionNode = itemNode.querySelector(".gallery-item-caption")
      return {
        source: imageNode.getAttribute("src"),
        width: imageNode.getAttribute("width"),
        height: imageNode.getAttribute("height"),
        caption: captionNode.innerText.trim()
      }
    })
  }

  bindListenerToDocument(callback) {
    this.document.addEventListener("DOMContentLoaded", callback)
  }

  getPageData() {
    const featureVideo = this.document.getElementsByClassName("video-wrapper").length
    const normalVideo = this.document.getElementsByClassName("video-js").length
    const imageGallery= this.document.getElementsByClassName("new-image-gallery-container").length

    const element = this.document.getElementById("page-data")

    return {
      categories: element.dataset.categories,
      pageNo: element.dataset.pageNo,
      articleSlug: element.dataset.articleSlug,
      pageType: element.dataset.pageType,
      events: element.dataset.events,
      categoriesTags: element.dataset.categoriesTags,
      publisherSlug: element.dataset.publisherSlug,
      authorSlug: element.dataset.authorSlug,
      tagName: element.dataset.tagName,
      sourceName: element.dataset.sourceName,
      brandName: element.dataset.brandName,
      brandNameSlug: element.dataset.brandNameSlug,
      channel: element.dataset.channel,
      section: element.dataset.section,
      contentSource: element.dataset.contentSource,
      featureVideo: featureVideo,
      normalVideo: normalVideo,
      imageGallery: imageGallery,
      searchKeywords: element.dataset.searchKeywords
    }
  }
}

export default DomUtils
