function scrollFourUpBlock(block) {
  const container = block.querySelector(".rui-grid")
  const viewportWidth = container.clientWidth
  const scrollWidth = container.scrollWidth
  if (viewportWidth < scrollWidth) {
    const tiles = Array.from(container.querySelectorAll(".rui-grid-row .lifestyle-tile"))

    const targetTileIndex = 1 // we want to centre the second tile
    const targetTile = tiles[targetTileIndex]
    const tileOffset = targetTile.offsetLeft
    const tileWidth = targetTile.offsetWidth
    const contentWidth = tileOffset * 2 + tileWidth // tile width plus same space on both sides
    const scrollLeft = Math.round((contentWidth - viewportWidth) / 2)
    if (scrollLeft > 0) {
      container.scrollLeft = scrollLeft
    }
  }
}

function scrollFourUpBlocks() {
  const blocks = document.querySelectorAll(".lifestyle-four-up-block");
  [...blocks].forEach(scrollFourUpBlock)
}

document.addEventListener("DOMContentLoaded", scrollFourUpBlocks)
