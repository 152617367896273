import gql from "graphql-tag"
import client from "../utilities/apollo-client.util"

const relatedPostsWithGalleriesQuery = (postId, pageFrom=0, pageSize=5) => gql`{
    related_posts_with_galleries_by_post_id(post_id:${postId}, page_from:${pageFrom}, page_size:${pageSize}){
      total
      posts {
        id
        galleries {
          title
          images {
            source
            width
            height
            alt
            caption
          }
        }
      }
    }
  }
`

const getRelatedPostsWithGalleries = data => data && data.related_posts_with_galleries_by_post_id

const hasGraphQLErrors = apolloQueryResult => apolloQueryResult.errors && apolloQueryResult.errors.length > 0

const processResponse = apolloQueryResult => {
  if (hasGraphQLErrors(apolloQueryResult)) {
    const message = JSON.stringify({
      message: "GraphQL error",
      errors: apolloQueryResult.errors
    })
    throw new Error(message)
  }

  return getRelatedPostsWithGalleries(apolloQueryResult.data)
}

const getRelatedPostsWithGalleriesByPostId = (postId, pageFrom, pageSize) =>
  client.query({
        query: relatedPostsWithGalleriesQuery(postId, pageFrom, pageSize)
      })
    .then(processResponse)
    .catch(error => error)

export default getRelatedPostsWithGalleriesByPostId
