import React, { Component } from "react"
import EmbeddedView from "./image-gallery-embedded-view.component"
import {getFullScreenImageSizes, getFullScreenImageSrcset, isLandscape, isChromeNotOnIOS} from "./image-gallery.utils"

export default class RelatedGalleryTeaserSlide extends Component {
  constructor(props) {
    super(props)
  }

  getRenderedImageSize(coverImage) {
    const containerBounds = document.querySelector(".image-gallery-slides").getBoundingClientRect()
    const BOTTOM_MARGIN = isLandscape() ? 0 : 141
    const outerSize = {height: containerBounds.height - BOTTOM_MARGIN, width: containerBounds.width}
    if (isChromeNotOnIOS() && coverImage.width <= outerSize.width && coverImage.height <= outerSize.height) {
      return {height: coverImage.height, width: coverImage.width}
    }
    // Image is higher or wider than container
    const imageRatio = coverImage.height / coverImage.width
    const containerRatio = outerSize.height / outerSize.width
    if (containerRatio <= imageRatio) {
      return {height: outerSize.height, width: outerSize.height / imageRatio}
    }
    return {height: outerSize.width * imageRatio, width: outerSize.width}
  }

  render() {
    const {gallery, renderNextGallery} = this.props
    const coverImage = gallery.images[0]
    const imageSize = this.getRenderedImageSize(coverImage)
    return (
      <div className={`image-gallery-image image-gallery-image-cover ${isLandscape() && "dock"}`}>
        <style>
          {`
            .image-gallery .image-gallery-slide .image-gallery-image .image-gallery-embedded-view__cover img {
              height: 100%;
            }
            .image-gallery-embedded-view__bar {
              width: ${imageSize.width}px;
              height: ${imageSize.height * 0.3}px;
              left: calc((100% - ${imageSize.width}px) / 2);
              bottom: calc((100% - ${imageSize.height}px) / 2);
            }
          `}
        </style>
        <EmbeddedView
          onClick={renderNextGallery}
          embeddedImage={gallery.images[0]}
          galleryTitle={gallery.title}
          imgLength={gallery.images.length}
          getImageSrcset={getFullScreenImageSrcset}
          getImageSizes={getFullScreenImageSizes}
        />
      </div>
    )
  }
}
