import BrightcoveUtil from "./brightcove.util"

class EventRegister {
  constructor(telemetry, tealiumService, window, domUtils) {
    this.tealiumService = tealiumService
    this.telemetry = telemetry
    this.window = window
    this.domUtils = domUtils
    this.playListCallbacksByEvent = {}
    this.singleVideoCallbacksByEvent = {}
  }

  _getVideoPlayers = (() => {
    let videoPlayers
    return () => {
      if (videoPlayers == undefined) {
        videoPlayers = this.domUtils.getVideoIds().map(
          videoId => this.window.videojs(videoId)
        )
      }
      return videoPlayers
    }
  })()

  _addVideoCallback(event, eventCallback, listOfCallbacks){
    if(!(event in listOfCallbacks)){
      listOfCallbacks[event] = []
    }
    listOfCallbacks[event].push(eventCallback)
  }

  addListenerForVideoEvents() {
    let videoPlayers = this._getVideoPlayers()
    videoPlayers.forEach((videoPlayer) => {
      videoPlayer.ready(() => {
        if (BrightcoveUtil.isPlaylist(videoPlayer)) {
          BrightcoveUtil.bindCallbacks(videoPlayer, this.playListCallbacksByEvent)
        } else {
          BrightcoveUtil.bindCallbacks(videoPlayer, this.singleVideoCallbacksByEvent)
        }
      })
    })
  }

  validateAndSendTelemetryOnSearch(formId) {
    return () => {
      const searchForm = this.domUtils.document.getElementById(formId)
      if (searchForm) {
        searchForm.addEventListener("submit", (event) => {
          event.preventDefault()

          if (searchForm.checkValidity()) {
            const searchInput = this.domUtils.document.getElementById("s")
            const searchFilter = this.domUtils.document.getElementById("unique-id")

            let searchFilterValue = null

            if (searchFilter) {
              searchFilterValue = searchFilter.value
            }

            const payload = this.createSearchTelemetryPayload(
              searchForm.id,
              this.domUtils.getPageData(),
              searchInput.value,
              searchFilterValue
            )
            this.telemetry.send(payload)
            searchForm.submit()
          }
        })
      }
    }
  }

  createSearchTelemetryPayload(searchFormId, pageData, searchQuery, searchFilter = null) {
    const payload = {
      event: "search",
      formId: searchFormId,
      pageData: pageData,
      searchQuery: searchQuery
    }

    if (searchFilter) {
      payload.filterCategory = searchFilter
    }

    return payload
  }

  bindEvent = () => {
    this._addVideoCallback("loadedmetadata",
      videoPlayer => {
        this.tealiumService.trackVideoPlayed(videoPlayer)
      },
      this.playListCallbacksByEvent
    )

    this._addVideoCallback("loadedmetadata",
      videoPlayer => {
        this.tealiumService.trackVideoPlayed(videoPlayer)
      },
      this.singleVideoCallbacksByEvent
    )

    this.addListenerForVideoEvents()

    const guidesSearchListener = this.validateAndSendTelemetryOnSearch("adviceSearchForm")
    const navigationSearchListener = this.validateAndSendTelemetryOnSearch("searchform")

    this.domUtils.bindListenerToDocument(guidesSearchListener)
    this.domUtils.bindListenerToDocument(navigationSearchListener)
  }
}

export default EventRegister
