import React, { Component } from "react"
import EmbeddedView from "./image-gallery-embedded-view.component"
import PropTypes from "prop-types"
import Toggle from "../open-toggle.component"
import { ImageGalleryFullScreen } from "./"
import RelatedGallery from "./related-gallery.component"

class ImageGallery extends Component {
  constructor(props) {
    super(props)
  }

  sendGalleryOpenEvent = () => {
    this.props.tealiumService.trackGalleryLaunch()
  }

  sendGalleryViewedCompleteEvent = (() => {
    const defaultIndex = 0
    const galleryViewedIndices = [defaultIndex]
    let completeEventSent = false
    const allGalleryIndex = Array.from(Array(this.props.images.length).keys())
    return (viewedGalleryIndex) => {
      galleryViewedIndices.push(viewedGalleryIndex)

      let galleryViewCompleted = allGalleryIndex.every((index) => {
        return galleryViewedIndices.includes(index)
      })
      if (galleryViewCompleted && !completeEventSent) {
        this.props.tealiumService.trackGalleryViewedComplete()
        completeEventSent = true
      }
    }
  })()

  onSlideChange = viewingGalleryIndex => {
    this.sendGalleryViewedCompleteEvent(viewingGalleryIndex)
  }

  render() {
    const {
      images,
      title,
      advertorialModel,
      domUtils
    } = this.props
    return (
      <Toggle>
        {({ isOpen, close, open }) => <div>
          <EmbeddedView
            onClick={() => {
              open()
              domUtils.disablePageScroll()
              this.sendGalleryOpenEvent()
            }}
            embeddedImage={images[0]}
            galleryTitle={title}
            imgLength={images.length}/>
          {
            isOpen && (
              <RelatedGallery>
              {({nextGallery, refreshNextGallery}) =>
                <ImageGalleryFullScreen
                  nextGallery={nextGallery}
                  refreshNextGallery={refreshNextGallery}
                  onClose={() => {
                    close()
                    domUtils.enablePageScroll()
                  }}
                  items={images}
                  galleryTitle={title}
                  advertorialModel={advertorialModel}
                  onSlideChange={this.onSlideChange}/>}
              </RelatedGallery>
            )
          }
        </div>}
      </Toggle>
    )
  }
}

ImageGallery.propTypes = {
  images: PropTypes.array.isRequired,
  title: PropTypes.string,
  tealiumService: PropTypes.object.isRequired
}

export default ImageGallery
