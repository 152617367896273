import React from "react"

const ImageGalleryBar = ({ title, imgLength }) => (
  <div className={`image-gallery-embedded-view__bar ${title ? "" : "image-gallery-embedded-view__bar--center"}`}>
    {title && <h4 className="image-gallery-embedded-view__bar-title">{title}</h4>}
    <div className="image-gallery-embedded-view__bar-info">
      <span>{imgLength} {imgLength > 1 ? "images" : "image"}</span>
    </div>
  </div>
)

export default ImageGalleryBar
