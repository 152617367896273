export class BrandService {
  constructor(locationBasedBranding) {
    this.locationBasedBranding = locationBasedBranding
    this.brand = {
      name: "Suncorp",
      logo: "https://www.realestate.com.au/blog/images/600x144-fit,progressive/2017/11/14121222/SuncorpV3-e1622795661243.jpg"
    }
  }

  getAllBrandLogoElementsByAlt(){
    const images = document.getElementsByTagName("img")
    return Array.from(images).filter(imageDOM => imageDOM.alt === this.brand.name)
  }

  replaceLogo() {
    const brandLogoElements = this.getAllBrandLogoElementsByAlt()
    const hasLogosNeedToBeReplaced = brandLogoElements.length > 0

    if(hasLogosNeedToBeReplaced){
      this.locationBasedBranding.getBrandingBasedOnLocation(this.brand.name).then((brand) => {
        const newBrand = brand
          ? { logo: brand.logo.url, name: brand.name}
          : this.brand

        brandLogoElements.forEach(imageDOM => {
          imageDOM.src = newBrand.logo
          imageDOM.alt = newBrand.name
        })
      })
    }
  }
}
