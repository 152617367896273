class CookieUtils {
  static grab(keys) {
    return document.cookie.split(";").map(function(kv) {
      const [key, value] = kv.split("=")
      return {
        key: key.trim(),
        value: value?.trim()
      }
    }).filter(function(cookie) {
      if (keys.includes(cookie.key)) {
        return true
      }
      return false
    }).reduce((map, obj) => {
      map[obj.key] = obj.value
      return map
    }, {})
  }
}

export default CookieUtils
