import React, { Component } from "react"
import PropTypes from "prop-types"
// until we get tree shaking, import these individually to reduce bundle size:
// import { map, pick } from "lodash"
import map from "lodash/map"
import pick from "lodash/pick"

import { TimeUtils, BrightcoveUtil } from "../../utilities"

import Playlist from "./playlist.component"
import Loading from "./loading.component"
import Error from "./error.component"

export const PLAYLIST_CHECK_INTERVAL_MILLISECONDS = 1000
export const PLAYLIST_LOADING_TIMEOUT_SEC = 20
const PLAYLIST_LOAD_FAIL_TIMEOUT_SEC = 10
const VIDEO_FIELDS = ["platform_id", "image_url", "title"]

class IrisRelatedVideos extends Component {
  static propTypes = {
    videojs: PropTypes.func.isRequired,
    videoId: PropTypes.string.isRequired,
    videoContainerId: PropTypes.string.isRequired,
    getIris: PropTypes.func.isRequired
  }

  constructor(props, context) {
    super(props, context)

    this.state = {
      isVisible: false,
      isAdPlaying: false,
      playingIndex: null,
      playlistLoading: false,
      playlistLoadStart: null, // the time this event started
      playlistLoadError: false,
      playlistLoaded: false,
      playlistVideos: [],
      checkForPlaylistInterval: null,
      playlistLoadErrorTimeout: null
    }
  }

  componentDidMount() {
    const video = this.props.videojs(this.props.videoContainerId)
    // TODO: figure out how to do this sooner if the user is quickly clicking play
    video.on("loadedmetadata", this.handleLoadedMetadata)
    video.on("ads-pod-started", () => {
      this.setState({
        isAdPlaying: true,
        isVisible: true,
        playingIndex: null
      })
    })
    video.on("ads-pod-ended", () => {
      this.setState({
        isAdPlaying: false
      })
      this.startCheckingPlaylist()
    })
    video.on("playing", () => {
      const playingIndex = this.props.getIris().getCurrentIndex()
      this.setState({
        playingIndex
      })
    })
  }

  componentWillUnmount() {
    if (this.state.checkForPlaylistInterval) {
      clearInterval(this.state.checkForPlaylistInterval)
    }
    if (this.state.playlistLoadErrorTimeout) {
      clearTimeout(this.state.playlistLoadErrorTimeout)
    }
  }

  handleLoadedMetadata = () => {
    this.startCheckingPlaylist()
  }

  startCheckingPlaylist = () => {
    if (this.state.checkForPlaylistInterval) {
      clearInterval(this.state.checkForPlaylistInterval)
    }
    const checkForPlaylistInterval = setInterval(
      this.checkForPlaylist,
      PLAYLIST_CHECK_INTERVAL_MILLISECONDS
    )
    this.setState({
      isVisible: true,
      checkForPlaylistInterval,
      playlistLoadStart: new Date(),
      playlistLoading: true,
      playlistLoadError: false,
      playlistLoadErrorTimeout: null
    })
  }

  checkForPlaylist = () => {
    const rawPlaylist = this.props.getIris().getPlaylist()
    const playlist = this.mapPlaylist(rawPlaylist)

    if (playlist && playlist.length) {
      if (playlist.length > 1) {
        const hasPlaylistChanged = this.hasPlaylistChanged(playlist)
        this.setState({
          isVisible: true,
          playlistVideos: playlist,
          playlistLoaded: true
        })
        if (hasPlaylistChanged) {
          this.stopCheckingForPlaylist()
        } else {
          if (
            TimeUtils.LongerThan(
              this.state.playlistLoadStart,
              PLAYLIST_LOADING_TIMEOUT_SEC * 1000
            )
          ) {
            this.stopCheckingForPlaylist()
          }
        }
      } else {
        if (this.state.isAdPlaying) {
          // prevent Safari from reporting an error too soon
          return
        }
        if (
          TimeUtils.LongerThan(
            this.state.playlistLoadStart,
            PLAYLIST_LOADING_TIMEOUT_SEC * 1000
          )
        ) {
          this.startShowingError()
        }
      }
    } else {
      this.startShowingError()
    }
  }

  stopCheckingForPlaylist = () => {
    clearInterval(this.state.checkForPlaylistInterval)
    this.setState({
      playlistLoading: false,
      playlistLoadStart: null,
      checkForPlaylistInterval: null
    })
  }

  mapPlaylist = playlist => map(playlist, item => pick(item, VIDEO_FIELDS))

  hasPlaylistChanged = playlist => {
    return BrightcoveUtil.hasPlaylistChanged(this.state.playlistVideos, playlist)
  }

  startShowingError = () => {
    const playlistLoadErrorTimeout = setTimeout(
      this.hideError,
      PLAYLIST_LOAD_FAIL_TIMEOUT_SEC
    )
    this.setState({
      isVisible: true,
      playlistLoadError: true,
      playlistLoadErrorTimeout
    })
  }

  hideError = () => {
    this.setState({
      isVisible: false,
      playlistLoadError: false,
      playlistLoadErrorTimeout: null
    })
  }

  skipToVideo = index => {
    if (index !== this.state.playingIndex) {
      this.props.getIris().skipToIndex(index)
    }
    // don't need to update the state because that will happen after the target video starts playing
  }

  render() {
    const {
      isVisible,
      isAdPlaying,
      playlistLoaded,
      playlistLoadError,
      playlistVideos,
      playingIndex
    } = this.state

    if (isVisible) {
      let ui = null
      if (playlistLoadError) {
        ui = <Error />
      } else if (playlistLoaded && !isAdPlaying) {
        ui = (
          <Playlist
            videos={playlistVideos}
            videoClicked={index => this.skipToVideo(index)}
            nowPlayingIndex={playingIndex}
          />
        )
      } else {
        ui = <Loading adPlaying={isAdPlaying} />
      }
      return <div className="related-videos">{ui}</div>
    }

    return null
  }
}

export default IrisRelatedVideos
