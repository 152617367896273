import React, {PureComponent} from "react"
import advertConfig from "./advert.config"
import emitter from "../../utilities/emitter-singleton"
import {currentDefinition} from "../image-gallery/image-gallery.utils"
import {Advert} from "@rea-group/ad-kit"

const PHOTO_SKY_DEFINITION = {
  M: { media: "(max-width:767px)", adSize: "320x50", position: "top" },
  L: { media: "(min-width:768px) and (max-width:1140px)", adSize: "160x600", position: "side" },
  XL: { media: "(min-width:1141px)", adSize: "300x600,160x600", position: "side" }
}

const currentPhotoSkyDefinition = () => PHOTO_SKY_DEFINITION[currentDefinition(PHOTO_SKY_DEFINITION)]

export default class GalleryAdvert extends PureComponent {

  static defaultProps = {
    interval : 30000
  }

  constructor(props) {
    super(props)
    this.state = {
      lastTimestamp: Date.now()
    }
  }

  shouldRefresh(){
    return (Date.now() - this.state.lastTimestamp) >= this.props.interval
  }

  refresh = () => {
    if(this.shouldRefresh()){
      this.setState({lastTimestamp : Date.now()})
    }
  }

  componentDidMount(){
    emitter.on("refreshGalleryAd", this.refresh)
  }

  componentWillUnmount() {
    emitter.removeListener("refreshGalleryAd", this.refresh)
  }

  isFirstAd = (() => {
    let hasInitAdvert = false
    return () => {
      if (hasInitAdvert)
        return false
      hasInitAdvert = true
      return true
    }
  })()

  render() {
    const advertDefinition = currentPhotoSkyDefinition()
    if(this.props.advertPosition !== advertDefinition.position){
        return null
    }
    const adConfig = advertConfig.galleryAd(advertDefinition, this.isFirstAd())
    const mergedParams = {
      ...this.props.advertModel.getParams(),
      ...adConfig.params
    }
    const mergedConfig = {
      ...this.props.advertModel.getConfig(),
      ...adConfig.config
    }
    return (<Advert config={mergedConfig} params={mergedParams} adKey={mergedConfig.sz} />)
  }
}
