import React from "react"

const Error = () => (
  <div className="related-videos-error">
    <p className="related-videos-error-text">
      We had trouble making your playlist, but we&rsquo;ll try again when you
      watch another video.
    </p>
  </div>
)

export default Error
