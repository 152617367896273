import React from "react"
import ImageGalleryBar from "./image-gallery-bar.component"
import {getSrcset, getEmbeddedViewSizes} from "./image-gallery.utils"

const EmbeddedView = ({embeddedImage, galleryTitle, imgLength, onClick, getImageSrcset=getSrcset, getImageSizes=getEmbeddedViewSizes}) =>
  (
    <div
      onClick={onClick}
      role="button"
      tabIndex="0"
      aria-label="Open image gallery"
      className="image-gallery-embedded-view__cover">
      <img
        alt={embeddedImage.caption || ""}
        src={embeddedImage.source}
        srcSet={getImageSrcset(embeddedImage)}
        sizes={getImageSizes()}/>
      <ImageGalleryBar title={galleryTitle} imgLength={imgLength} />
    </div>
  )

export default EmbeddedView
