import ApolloClient from "apollo-client"
import { createHttpLink } from "apollo-link-http"
import { InMemoryCache } from "apollo-cache-inmemory"

const contentAPIHost = process.env.CONTENT_API_HOST
const link = createHttpLink({ uri: `${contentAPIHost}/graphql` })

const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
})

export default client
