export default class Tracker {
  static trackByImg(url) {
    const e = document.createElement("img")
    e.setAttribute("src", url)
    e.setAttribute("style", "height: 0; width: 0; border: 0")
    e.setAttribute("onload", "this.parentNode.removeChild(this)")
    document.body.appendChild(e)
  }

  static initializeTracking() {
    document.addEventListener("DOMContentLoaded", ()=> {
      if (document.querySelector("#brand-sponsor-hub-track")) {
        //track for REA
        this.trackByImg("https://beacon.krxd.net/event.gif?event_id=LEurhp0y&event_type=ad")
      }
    })
  }
}
