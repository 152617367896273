export default class TimeUtils {
  static SecondsToMMSS(totalSeconds) {
    var minutes = Math.floor((totalSeconds) / 60)
    var seconds = totalSeconds - (minutes * 60)
    // round seconds
    seconds = Math.round(seconds * 100) / 100

    var result = minutes < 10 ? "0" + minutes : minutes
    result += ":" + (seconds < 10 ? "0" + seconds : seconds)
    return result
  }

  static LongerThan(startTime, timeSpanInMsec) {
    const timeNow = new Date()
    const duration = timeNow - startTime
    return (duration >= timeSpanInMsec)
  }
}
