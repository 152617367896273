import ImageGallery from "react-image-gallery"

class ImageGalleryWrapper extends ImageGallery {
  constructor(props) {
    super(props)
    const { _slideRight, _canSlideRight, _handleOnSwipedTo } = this
    this.props.slideRight && (this._slideRight = this.props.slideRight(_slideRight.bind(this)))
    this.props.canSlideRight && (this._canSlideRight = this.props.canSlideRight(_canSlideRight.bind(this)))
    this.props.handleOnSwipedTo && (this._handleOnSwipedTo = this.props.handleOnSwipedTo(_handleOnSwipedTo.bind(this)))
  }
}

export default ImageGalleryWrapper
