import React, {useEffect, useState} from "react"
import StickyContainer from "./sticky.container.component"
import {Advert} from "@rea-group/ad-kit"

const Advertorial = (props) => {
  const {container, visibility, display, sticky, config, params} = props
  const [advertConfig, setAdvertConfig] = useState()

  useEffect(() => {
    if (container) {
      if (visibility !== "hidden" && display !== "none") {
        setAdvertConfig({
          config: config,
          params: params
        })
      }
    }
  }, [])

  const renderAd = (advertConfig) => {
    return advertConfig && <Advert adKey={advertConfig.config.sz} config={advertConfig.config} params={advertConfig.params} />
  }

  return (
    sticky ?
      <StickyContainer offsetTop={props.offsetTop}>
        {renderAd(advertConfig)}
      </StickyContainer>
      : renderAd(advertConfig)
  )
}

export default Advertorial

