import { removeEmptyField } from "../utilities/objectUtils"

export const articleDataTransformer = (page) => {
  if (page.pageType !== "post") {
    return {}
  }

  return {
    article: {
      schema: "iglu:au.com.realestate/article/jsonschema/1-0-1",
      data: removeEmptyField({
        article_title: page.articleSlug,
        author:
          page.brandNameSlug !== "" ? page.brandNameSlug : page.authorSlug,
        publisher: page.publisherSlug,
        sponsor: page.brandNameSlug
      })
    }
  }
}
