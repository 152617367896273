export const getSrcset = ({width, height, source}, srcSetWidth = [200, 400, 600, 800, 1000, 1200, 1400, 1600]) => {
  const ratio = width / height
  return srcSetWidth.map(width =>
    source.replace(/\d+x\d+/, `${width}x${Math.round(width / ratio)}`) + ` ${width}w`
  ).join(",")
}

export const getFullScreenImageSrcset = (image) =>
  getSrcset(image, [200, 400, 600, 800, 1000, 1200, 1400, 1600, 1800, 2000, 2200])

export const getEmbeddedViewSizes = () => {
  return "(max-width: 767px) 100vw, (max-width: 939px) 60vw, (max-width: 1112px) 70vw, 800px"
}

export const getFullScreenImageSizes = () => {
  const sidebarWidth = 260
  return `(max-width: 767px) 100vw, calc(100vw - ${sidebarWidth}px)`
}

export const isTouchableDevice = () => {
  const isPointerEnabled = navigator.pointerEnabled || navigator.msPointerEnabled
  return !isPointerEnabled && "ontouchstart" in window
}

export const isLandscape = () =>
  window.matchMedia("screen and (max-width: 1023px) and (max-height: 450px) and (orientation: landscape)").matches

export const currentDefinition = (layoutDefinition) => {
  for (let item in layoutDefinition) {
    if (window.matchMedia(layoutDefinition[item].media).matches) {
      return item
    }
  }
}

export const isChromeNotOnIOS = () => {
  const userAgent = window.navigator.userAgent
  const isIOSChrome = userAgent.match("CriOS")
  if (isIOSChrome) {
    return false
  }
  const isChromium = window.chrome
  return isChromium !== null && typeof isChromium !== "undefined"
}
