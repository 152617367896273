import Hammer from "hammerjs"

class HandleGalleryDrag {
  ticking = false

  constructor(el, callback) {
    this.el = el
    this.closeCallback = callback
    this._init()
  }

  removeEventListener() {
    this.mc.off("panstart panmove", this._handlePan)
    this.mc.off("hammer.input", this._handleInput)
    this.el = undefined
  }

  _init() {
    this.mc = new Hammer.Manager(this.el)
    this.mc.add(new Hammer.Pan({ threshold: 3 }))

    this.mc.on("panstart panmove", this._handlePan)
    this.mc.on("hammer.input", this._handleInput)
  }

  _handlePan = (event) => {
    event.preventDefault()
    if(event.additionalEvent === "panup" || event.additionalEvent === "pandown") {
      this.el.className = this.el.className.split(" ").filter((name) => name !== "image-gallery-fullscreen--animate").join(" ")
      this._requestElementUpdate({
        transform: {
          y: event.deltaY
        },
        opacity: 1 - Math.abs(event.deltaY)/700
      })
    }
  }

  _handleInput = (event) => {
    const OFFSET_THRESHOLD = 80
    if(event.isFinal) {
      if(Math.abs(event.deltaY) > OFFSET_THRESHOLD) {
        this.closeCallback()
      } else {
        this._resetElement()
      }
    }
  }

  _resetElement() {
    this.el.className = this.el.className + " image-gallery-fullscreen--animate"
    this.ticking = false // To make sure reset style trigger after style update

    this._requestElementUpdate({
      transform: {
        y: 0
      },
      opacity: 1
    })
  }

  _updateElementStyle = (style) => {
    return () => {
      const transformValue = `translate3d(0, ${style.transform.y}px, 0)`
      this.el.style.webkitTransform = transformValue
	    this.el.style.mozTransform = transformValue
	    this.el.style.transform = transformValue
      this.el.style.backgroundColor = `rgba(51, 63, 72, ${style.opacity})`
      this.ticking = false
    }
  }

  _requestElementUpdate(style) {
    if(!this.ticking) {
      requestAnimationFrame(this._updateElementStyle(style))
      this.ticking = true
    }
  }
}

export default HandleGalleryDrag
