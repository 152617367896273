export default class PerfToggleUtils {
  constructor(window) {
    this.window = window
  }

  isPerfToggleOn(name) {
    const queryRegExp = new RegExp("(\\?|&)" + name + "=")
    return queryRegExp.test(this.window.location.search)
  }

  isAnalyticsPerfToggleDisabled() { return this.isPerfToggleOn("perf_analytics_disabled") }
}
