export default class AdviceGuidesSearch {
  searchForm = null
  actionUrlTemplate = null
  select = null
  input = null

  initializeSearchForm = (searchForm) => {
    this.searchForm = searchForm
    this.actionUrlTemplate = this.searchForm.attributes["data-action-url"].value
    this.select = this.searchForm.querySelector("select.rui-select")
    this.input = this.searchForm.querySelector("input[type='text']")

    this.select.addEventListener("click", this.onAdviceCategoryChange)
    this.select.addEventListener("change", this.onAdviceCategoryChange)
  }

  onAdviceCategoryChange = (event) => {
    const selectedValue = event.target.value
    const selectedOption = this.select.querySelector(`option[value="${selectedValue}"]`)
    const selectedCategoryCount = selectedOption.attributes["data-category-count"].value
    this.input.placeholder = `Search ${selectedCategoryCount} articles`
    this.searchForm.action = this.actionUrlTemplate.replace("{{category}}", selectedValue)
  }
}
