
export default class TelemetryService {

  send(telemetryPayload) {
    const telemetryRequest = new XMLHttpRequest()
    telemetryRequest.open("POST", process.env.TELEMETRY_ENDPOINT, true)
    telemetryRequest.setRequestHeader("Content-Type", "application/json")
    telemetryRequest.send(JSON.stringify(telemetryPayload))
  }
}
