import React from "react"
import {getFullScreenImageSizes, getFullScreenImageSrcset, isLandscape, isTouchableDevice, isChromeNotOnIOS} from "./image-gallery.utils"
import objectFitImages from "object-fit-images"
import GalleryAdvert from "../advert/gallery-advert.component"
import emitter from "../../utilities/emitter-singleton"
import RelatedGalleryTeaserSlide from "./related-gallery-teaser-slide.component"
import HandleDrag from "./handle-image-gallery-drag"
import ImageGalleryWrapper from "./image-gallery-wrapper"

const TIME_UNTIL_EXECUTION = 300
class ImageGalleryFullScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentSlide: 1,
      isLandscape: isLandscape(),
      isTouchable: isTouchableDevice(),
      isCoverOfNextGallery: false,
      currentGallery: {
        galleryTitle: props.galleryTitle,
        items: props.items
      },
      replacing: false
    }
  }

  slideRight = (originalSlideRight) => () => {
    if (this.state.isCoverOfNextGallery) {
      this.renderNextGallery()
    } else {
      return originalSlideRight()
    }
  }
  canSlideRight = (originalCanSlideRight) => () => {
    if (this.state.isCoverOfNextGallery) {
      return true
    } else {
      return originalCanSlideRight()
    }
  }

  handleOnSwipedTo = (originalhandleOnSwipedTo) => (slide, isFlick)=>{
    if (this.state.isCoverOfNextGallery && slide>0) {
      this.renderNextGallery()
    } else {
      return originalhandleOnSwipedTo(slide, isFlick)
    }
  }

  onOrientationChanged = () => {
    setTimeout(() => {
      this.setState({ isLandscape: isLandscape() })
    }, TIME_UNTIL_EXECUTION)
  }

  onSlideChange = currentSlide => {
    this.props.onSlideChange(currentSlide)
    this.setState(prevState => ({
      isCoverOfNextGallery: this.hasRelatedGallery() && currentSlide === prevState.currentGallery.items.length,
      currentSlide: currentSlide + 1,
      replacing : false
    }))
    emitter.emit("refreshGalleryAd")
    objectFitImages(document.querySelector(".image-gallery .center img")) //polyfill IE object-fit compatibility issue
  }

  renderNextGalleryCover = ({nextGallery}) => (
    <RelatedGalleryTeaserSlide
      gallery={nextGallery}
      renderNextGallery={this.renderNextGallery}
    />
  )

  renderNextGallery = () => {
    const { nextGallery, refreshNextGallery } = this.props
    this.setState({
      currentGallery: {
        galleryTitle: nextGallery.title,
        items: nextGallery.images
      },
      replacing: true // Cover the gallery to avoid react-image-gallery flashback
    }, () => {
      this._imageGallery.slideToIndex(0)
      refreshNextGallery()
    })
  }

  hasRelatedGallery = () => {
    const { nextGallery } = this.props
    return !!(nextGallery && nextGallery.images && nextGallery.images.length)
  }

  componentDidMount() {
    const { onClose } = this.props
    window.addEventListener("orientationchange", this.onOrientationChanged)
    objectFitImages(document.querySelector(".image-gallery .center img")) //polyfill IE object-fit compatibility issue
    isTouchableDevice() && (this.drag = new HandleDrag(this.wrapper, onClose))
  }

  componentWillUnmount() {
    window.removeEventListener("orientationchange", this.onOrientationChanged)
    this.drag && this.drag.removeEventListener()
  }

  renderNav = className => (onClick, disabled) =>
    (
      !disabled && <button className={className}
                           disabled={disabled}
                           onClick={onClick}/>
    )

  render() {
    const { onClose, advertorialModel, nextGallery } = this.props
    const { isTouchable, isLandscape, currentGallery, isCoverOfNextGallery, currentSlide, replacing } = this.state
    const { items, galleryTitle } = currentGallery
    let images = items.map(item => ({
      original: item.source,
      width: item.width,
      height: item.height,
      description: item.caption,
      srcSet: getFullScreenImageSrcset(item),
      sizes: getFullScreenImageSizes(),
      originalAlt: item.caption || ""
    }))

    if (this.hasRelatedGallery()) {
      images = [...images, {nextGallery, renderItem: this.renderNextGalleryCover, bulletClass: "image-gallery-bullet-hide"}]
    }
    return (
      <div ref={el => this.wrapper = el} tabIndex="-1" role="dialog" aria-hidden="true"
           className={`image-gallery-fullscreen ${isLandscape ? "dock" : ""} ${isCoverOfNextGallery ? "image-gallery-fullscreen--cover" : ""}`}>
        <div className="image-gallery-fullscreen__top-advert">
          {!isLandscape && <GalleryAdvert advertModel={advertorialModel} advertPosition="top"/>}
        </div>
        <div className="image-gallery-fullscreen__main-container">
          <div className="image-gallery-fullscreen__top-bar">
            <span className={"image-gallery-fullscreen__index"}>{currentSlide} / {items.length}</span>
            <h4 className={"image-gallery-fullscreen__title"}>{galleryTitle}</h4>
            <button
              aria-label="click to exit image gallery full screen"
              title="Close"
              className="image-gallery-fullscreen__close-button"
              onClick={onClose}
            />
          </div>
          { replacing && <div className="image-gallery-fullscreen__replacing"/>}
          { !isChromeNotOnIOS() &&
            <style>
              {`.image-gallery-fullscreen .image-gallery-fullscreen__main-container .image-gallery img {
                  object-fit: contain;
                }`}
            </style>}
          <ImageGalleryWrapper
            slideRight={this.slideRight}
            canSlideRight={this.canSlideRight}
            handleOnSwipedTo={this.handleOnSwipedTo}
            items={replacing ? [] : images}
            showThumbnails={false}
            showPlayButton={false}
            showFullscreenButton={false}
            showBullets={true}
            showNav={!isTouchable}
            onSlide={this.onSlideChange}
            ref={el => this._imageGallery = el}
            infinite={false}
            renderLeftNav={this.renderNav("image-gallery-left-nav")}
            renderRightNav={this.renderNav("image-gallery-right-nav")}
          />
        </div>

        <aside className="image-gallery-fullscreen__sidebar">
          <GalleryAdvert advertModel={advertorialModel} advertPosition="side"/>
        </aside>
      </div>
    )
  }
}

export default ImageGalleryFullScreen
