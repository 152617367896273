const isEmpty = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false
    }
  }
  return true
}

export const removeEmptyField = (data) => {
  return Object.keys(data)
    .filter((key) => {
      return !!data[key]
    })
    .reduce((acc, cur) => {
      if (data[cur] && typeof data[cur] === "object") {
        const curField = removeEmptyField(data[cur])
        return isEmpty(curField)
          ? acc
          : Object.assign(Object.assign({}, acc), { [cur]: curField })
      } else {
        return Object.assign(Object.assign({}, acc), { [cur]: data[cur] })
      }
    }, {})
}
