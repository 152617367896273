import React from "react"
import PropTypes from "prop-types"

import cogIcon from "../../../images/cog06.svg"

const Loading = props => {
  const { adPlaying } = props

  return (
    <div className="related-videos-loading">
      {adPlaying ? (
        <div className="related-videos-loading-text">
          We&rsquo;ll make a playlist, just for you,<br />
          after this ad.
        </div>
      ) : (
        <img
          className="related-videos-loading-icon"
          src={cogIcon}
          alt="Loading"
        />
      )}
    </div>
  )
}
Loading.propTypes = {
  adPlaying: PropTypes.bool.isRequired
}

export default Loading
