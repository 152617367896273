/* RUI: REA User Interface library - Forms - v1.1.1
   Copyright 2016, REA Group */

(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        define('rui-select',['jquery'], function ($) {
            return factory($);
        });
    } else {
        root.RUI = root.RUI || {};
        root.RUI.Select = factory(root.jQuery);
    }
}(this, function ($) {

        var Select = {},
            isAMenuOpen = false,
            didUserTab = false,
            ruiArrowIcon = "rui-icon-arrow-down",
            isTouch = (typeof (window.ontouchstart) !== 'undefined') ? true : false;
        if(!isTouch){
            $('html').addClass('rui-fancy-selects-on');
        }
        Select.FANCY_SELECT_TEMPLATE =
            '<div class="rui-select-wrapper">' +
                '<div id="{{id}}" class="{{classes}}">' +
                '<span>{{defaultLabel}}</span>' +
                '<input type="text" {{tabindex}} />' +
                '<i class="rui-icon '+ruiArrowIcon+'"></i>' +
                '</div>' +
                '<ul class="rui-select-menu">{{options}}</ul>' +
                '</div>';
        Select.SUBTITLE_TEMPLATE = '<div class="rui-select-subtitle">{{subtitle_text}}</div>';
        Select.OPTION_TEMPLATE = '<li class="{{classes}}" data-value="{{value}}" data-label="{{label}}">{{label}}{{subtitle}}</li>';
        Select.MULTI_OPTION_TEMPLATE =
            '<li class="{{classes}}">' +
            '<span class="rui-checkbox-hide"></span>'+
            '<input tabindex="-1" type="checkbox" ' +
            'value="{{value}}"{{checked}}/>' +
            '<span class="rui-label">{{label}}{{subtitle}}</span></li>';

        Select.replaceSelect = function () {
            $('select.rui-select:not(.rui-select-rendered)').each(function (i) {
                var $select = $(this);
                //non touch browsers use rui select
                if (!isTouch || window.forceUntouch) {
                    var fancySelect = $(Select.FANCY_SELECT_TEMPLATE
                        .replace(/{{id}}/g, getId($select))
                        .replace(/{{classes}}/g, 'rui-select-link ' + $select.attr('class'))
                        .replace(/{{defaultLabel}}/g, getDefaultLabel($select))
                        .replace(/{{tabindex}}/g, $select.attr('tabindex') ? "tabindex='" + $select.attr('tabindex') + "'" : "")
                        .replace(/{{options}}/g, getFancySelectOptions($select)));
                    $select.hide().after(fancySelect).removeAttr('tabindex').addClass("rui-select-rendered");
                } else {
                    $select.after('<i class="rui-icon-select-touch-friendly rui-icon '+ruiArrowIcon+'"></i>');
                }
            });
        };

        Select.refresh = function (selector) {
            if (!selector) {
                selector = "select.rui-select";
            }

            var $selects = $(selector);
            $.each($selects, function() {
                var $select = $(this);
                $select.next().find('.rui-select-link span').html(getDefaultLabel($select));
                $select.next().find('ul').html(getFancySelectOptions($select));
            });
        };

        function getDefaultLabel($select) {

            var $selectedOptions = $select.find('option:selected');
            if ($selectedOptions.length > 1) {
                return $selectedOptions.length + " options selected";
            } else {
                return $selectedOptions.text() || $select.find('option:first').text();
            }
        }

        function getId($select) {
            var id = $select.attr('id') || '';
            return id ? "rui-sel-" + id : '';
        }

        function getFancySelectOptions($select) {
            var selectOptions = '',
                template = $select.attr('multiple') ? Select.MULTI_OPTION_TEMPLATE : Select.OPTION_TEMPLATE;

            $select.find('option').each(function () {
                var $option = $(this),
                    selected = $option.is(':selected'),
                    disabled = $option.is(':disabled'),
                    classAttrValues = 'rui-select-list';
                if (selected) {
                    classAttrValues += ' rui-selected';
                }
                if (disabled) {
                    classAttrValues += ' rui-disabled';
                }

                var subtitle = $option.data('subtitle') ?
                    Select.SUBTITLE_TEMPLATE.replace(/{{subtitle_text}}/g, $option.data('subtitle')) : "";
                selectOptions += template
                    .replace(/{{classes}}/g, classAttrValues)
                    .replace(/{{value}}/g, $option.val())
                    .replace(/{{label}}/g, $option.text())
                    .replace(/{{subtitle}}/g, subtitle)
                    .replace(/{{checked}}/g, selected ? ' checked="checked"' : '');
            });
            return selectOptions;
        }

        function selectLink($li) {

            var selectVal,
                optionLabel,
                toggleWrapper = $li.parents('.rui-select-wrapper'),
                selectBox = toggleWrapper.prev('select.rui-select'),
                multiSelect = selectBox.attr('multiple');

            //Select value in real select
            if (multiSelect) {
                var checkboxes = toggleWrapper.find("input[type=checkbox]:checked"),
                    defaultLabel = selectBox.find('option:first').text();
                selectVal = [];
                checkboxes.each(function () {
                    selectVal.push($(this).val());
                });

                optionLabel = selectVal.length > 0 ? selectVal.length + " options selected" : defaultLabel;
                selectBox.val(selectVal);
            } else {
                optionLabel = $li.data('label');
                selectVal = $li.attr('data-value');
                selectBox.val(selectVal).trigger('click');
            }

            //Remove selected class from all options
            toggleWrapper.find('.rui-select-menu li').removeClass('rui-selected');

            //Add selected class to current selection
            $li.addClass('rui-selected');
            //Set text for selection
            toggleWrapper.find('.rui-select-link > span').html(optionLabel);
            //Hide container
            if (!multiSelect) {
                toggleWrapper.find('.rui-select-menu').hide();
                toggleWrapper.removeClass('rui-select-open');
            }
            toggleWrapper.find('input[type=text]').focus();

            if ($li.attr('data-value') && typeof $li.attr('data-value') !== 'undefined') {
                $li.closest('.rui-select-wrapper').find('.rui-select').addClass('rui-selected');
            }
            else {
                $li.closest('.rui-select-wrapper').find('.rui-select').removeClass('rui-selected');
            }
            //fix change event , in some case, change event want to be
            // triggered , but after replace, set the select value by js won't
            // trigger change event,
            selectBox.change();
        }


        Select.bindEvents = function () {
            Select.bindMouseEvents();
            Select.bindKeyboardEvent();
            Select.bindAccessibilityEvents();
        };

        Select.bindAccessibilityEvents = function () {
            $('body').on('focus', '.rui-select-link input', function() {
                $(this).closest('.rui-select-wrapper').addClass('rui-select-focused');
            }).on('blur', '.rui-select-link input', function() {
                $(this).closest('.rui-select-wrapper').removeClass('rui-select-focused');
            }).on('keydown', '.rui-select-link', function(e) {
                if ((e.keyCode === 38 || e.keyCode === 40) && !$(this).closest('.rui-select-wrapper').hasClass('rui-select-open')) {
                    e.preventDefault();
                    $(this).click();
                }
            });
        };
        Select.bindMouseEvents = function () {
            $('body').on('click', '.rui-select-menu li', function (e) {
                if (!$(this).hasClass('rui-disabled')) {
                    var checkbox = $(this).find('[type=checkbox]');
                    if(checkbox.is(':checked')){
                        checkbox.prop('checked',false);
                    }else{
                        checkbox.prop('checked',true);
                    }
                    selectLink($(this));
                }
            });
            $('body').on('click', '.rui-select-link', function (e) {
                e.preventDefault();
                if ($(this).closest('.rui-select-wrapper').hasClass('rui-select-open')) {
                    $('.rui-select-menu').hide();
                    $(this).closest('.rui-select-wrapper').removeClass('rui-select-open');
                }
                else {

                    $('.rui-select-open').removeClass('rui-select-open');
                    $('.rui-select-menu').hide();
                    $(this).find('input').focus();
                    var menu = $(this).closest('.rui-select-wrapper').addClass('rui-select-open').find('.rui-select-menu');
                    menu.find('.rui-select-list').removeClass('rui-current-list');
                    menu.show().find('.rui-selected').first().addClass('rui-current-list');
                }

            });

            $('body').on('click', function (e) {
                var $openToggle = $('.rui-select-open');
                if ($openToggle.length > 0 && $(e.target).closest('.rui-select-wrapper').length === 0) {
                    $openToggle.removeClass('rui-select-open');
                    $('.rui-select-menu').hide();
                }
            });

            $('body').on('mouseover', '.rui-select-list', function (e) {
                $(this).parent().find('.rui-select-list').removeClass('rui-current-list');
                if (!$(e.target).hasClass('rui-disabled')) {
                    $(this).addClass('rui-current-list');
                }
            });
            $('body').on('mouseleave', '.rui-select-list', function (e) {
                $(this).removeClass('rui-current-list');
            });
        };

        Select.bindKeyboardEvent = function () {
            var that = this;
            this.positionItemInDivViewport = function (parentDiv, listItem) {
                if (typeof listItem.position() !== 'undefined') {
                    var parentScrolledPosition = parentDiv.scrollTop(),
                        focusItemScrolledPosition = listItem.position().top;
                    if (parentDiv.innerHeight() - 30 < focusItemScrolledPosition || parentScrolledPosition > focusItemScrolledPosition) {
                        parentDiv.scrollTop(parentScrolledPosition + focusItemScrolledPosition);
                    }
                }
            };
            $('body').keydown(function (e) {
                var currentMenu = $('.rui-select-wrapper .rui-select-menu:visible');
                if (currentMenu.length === 0) {
                    return;
                }
                var charCode = e.keyCode || e.which;
                if (charCode !== 9) {
                    didUserTab = false;
                    e.preventDefault();
                } else {
                    didUserTab = true;
                    $('.rui-select-open').removeClass('rui-select-open');
                    $('.rui-select-menu').hide();
                    $('.rui-select-wrapper').removeClass('rui-select-focused');
                }
            });
            $('body').keyup(function (e) {
                var currentMenu = $('.rui-select-wrapper .rui-select-menu:visible');

                if (currentMenu.length === 0) {
                    isAMenuOpen = false;
                    return;
                } else {
                    isAMenuOpen = true;
                }

                var charCode = e.keyCode || e.which;
                if (charCode !== 9) {
                    didUserTab = false;
                    e.preventDefault();
                } else {
                    didUserTab = true;
                }

                var currentList = currentMenu.find('.rui-select-list.rui-current-list');
                switch (charCode) {
                    case 38:
                    case 40:
                        if (isAMenuOpen) {
                            currentMenu.show();
                            currentMenu.closest(".rui-select-wrapper").addClass("rui-select-open");
                        }
                        break;
                }
                switch (charCode) {
                    case 27: // esc
                        currentMenu.hide();
                        $('.rui-select-open').removeClass('rui-select-open');
                        break;
                    case 38: // up
                        var prev;
                        if (currentList.length === 0 || currentList.is(':first-child')) {
                            prev = currentMenu.find('.rui-select-list:not(.rui-disabled)').last();
                        } else {
                            prev = currentList.prevAll('.rui-select-list:not(.rui-disabled)').first();
                        }
                        if (prev.length === 0) {
                            prev = currentMenu.find('.rui-select-list:not(.rui-disabled)').last();
                        }
                        currentMenu.find('.rui-select-list').removeClass('rui-current-list');
                        prev.addClass('rui-current-list');
                        that.positionItemInDivViewport(currentMenu, prev);
                        break;
                    case 40: // down
                        var next;
                        if (currentList.length === 0 || currentList.is(':last-child')) {
                            next = currentMenu.find('.rui-select-list:not(.rui-disabled)').first();
                        } else {
                            next = currentList.nextAll('.rui-select-list:not(.rui-disabled)').first();
                        }
                        if (next.length === 0) {
                            next = currentMenu.find('.rui-select-list:not(.rui-disabled)').first();
                        }
                        currentMenu.find('.rui-select-list').removeClass('rui-current-list');
                        next.addClass('rui-current-list');
                        that.positionItemInDivViewport(currentMenu, next);

                        break;
                    case 32: // spacebar
                    case 13: // enter
                        if (currentList.length === 0) {
                            currentList = currentMenu.find('.rui-selected');
                        }
                        if (currentList.length === 0) {
                            currentList = currentMenu.find('.rui-select-list').first();
                        }
                        currentList.addClass('rui-selected');
                        var checkbox = currentList.find('input[type=checkbox]');
                        checkbox.prop('checked', !checkbox.prop('checked'));
                        selectLink(currentList);
                        break;
                }
            });
        };

        $(function() {
            if($('body').data('rui-select-auto-init') !== false){
                Select.replaceSelect();
                Select.bindEvents();
            }
        });
        return Select;

    }
));


