import CookieUtils from "../utilities/cookie.utils"

const clientMappedBySource = {
  app_android: "android",
  app_iphone: "iphone",
  app_ipad: "ipad",
  app_mobile: "outdated_app"
}

const legacyRedirect = (_window = window) => {
  const { origin, pathname, search, hash } = _window.location
  const searchParams = new URLSearchParams(search)
  const queryParamClient = searchParams.get("client")
  const querySource = searchParams.get("source")
  const { client: cookieClient } = new CookieUtils.grab(["client"])
  if (
    Object.keys(clientMappedBySource).includes(querySource) &&
    !Object.values(clientMappedBySource).includes(cookieClient) &&
    !queryParamClient
  ) {
    searchParams.append("client", clientMappedBySource[querySource])
    const redirectUrl = `${origin}${pathname}?${searchParams.toString()}${hash}`
    _window.location.href = redirectUrl
    return true
  }
  return false
}

export default legacyRedirect
