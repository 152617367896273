import { Component } from "react"
import { getRelatedPostsWithGalleriesByPostId } from "../../services"

class RelatedGallery extends Component {
  constructor() {
    super()
    this.page = 0
    this.relatedGalleries = []
    this.state = {
      nextRelatedGallery: {}
    }
  }

  getRelatedGalleries = () => {
    const postId = document.querySelector(".main-container").dataset.postid
    getRelatedPostsWithGalleriesByPostId(postId, this.page).then(result => {
      this.relatedGalleries = result && result.posts.length > 0 ? result.posts.flatMap((post) => post.galleries) : []
      this.setState({nextRelatedGallery: this.relatedGalleries.shift() || {}})
    }).catch((e) => {
      console.error(e)
      this.setState({nextRelatedGallery: {}})
    })
  }

  refreshRelatedGallery = () => {
    if(this.relatedGalleries.length > 0 ) {
      this.setState({nextRelatedGallery: this.relatedGalleries.shift()})
    } else {
      this.page++
      this.getRelatedGalleries()
    }
  }

  componentDidMount() {
    this.getRelatedGalleries()
  }

  render() {
    const { nextRelatedGallery } = this.state
    return this.props.children({
      nextGallery: nextRelatedGallery,
      refreshNextGallery: this.refreshRelatedGallery
    })
  }
}

export default RelatedGallery
