import React, { Component } from "react"
import PropTypes from "prop-types"

import truncate from "lodash/truncate"

import { BrightcoveUtil } from "../../utilities"

export const RELATED_VIDEO_TITLE_LENGTH = 45
export const VIDEO_WIDTH = 170

class Playlist extends Component {
  static propTypes = {
    videos: PropTypes.arrayOf(
      PropTypes.shape({
        platform_id: PropTypes.string.isRequired,
        image_url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
      })
    ).isRequired,
    videoClicked: PropTypes.func.isRequired,
    nowPlayingIndex: PropTypes.number
  }

  componentDidUpdate(prevProps) {
    const { nowPlayingIndex } = this.props
    if (prevProps.nowPlayingIndex !== nowPlayingIndex ||
      BrightcoveUtil.hasPlaylistChanged(prevProps.videos, this.props.videos)
    ) {
      this.scrollElement.scrollLeft = nowPlayingIndex * VIDEO_WIDTH
    }
  }

  renderVideoThumbnail = (video, index) => {
    const { nowPlayingIndex, videoClicked } = this.props
    const isPlaying = index === nowPlayingIndex
    const classes = ["related-video"]
    if (isPlaying) {
      classes.push("playing")
    }
    const shortTitle = truncate(video.title, {
      length: RELATED_VIDEO_TITLE_LENGTH,
      omission: "...",
      separator: /\W?\s+/
    })

    return (
      <li
        key={video.platform_id}
        className={classes.join(" ")}
        onClick={() => videoClicked(index)}
      >
        <div className="maintain-ratio--sixteen-nine">
          <div className="content">
            <img
              src={video.image_url}
              alt={video.title}
              className="related-video-thumbnail"
            />
            <div className="related-video-title-container">
              <span className="related-video-title" title={video.title}>
                {shortTitle}
              </span>
            </div>
          </div>
        </div>
      </li>
    )
  }

  render() {
    const { videos } = this.props

    return (
      <div
        className="related-videos-scroll"
        ref={scrollElement => { this.scrollElement = scrollElement }}
      >
        <ul className="related-videos-list">
          {videos.map((video, index) =>
            this.renderVideoThumbnail(video, index)
          )}
        </ul>
      </div>
    )
  }
}

export default Playlist
