import {
  urlInfo,
  getDeviceFromSource,
  getDeviceFromClient
} from "./platform.utils"

// Adapted and modified from https://git.realestate.com.au/cobra-psw/property-listings-web/blob/master/src/js/util/device-capability/screenType.js

const matchMediaSupported = () => "matchMedia" in window
const matches = (mediaQuery) =>
  matchMediaSupported() && window.matchMedia(mediaQuery).matches

const LAYOUT_DEFINITION = {
  S: "(max-width:767px)",
  M: "(min-width:768px) and (max-width:970px)",
  L: "(min-width:971px) and (max-width:1200px)",
  XL: "(min-width:1201px)"
}

const currentDefinition = () => {
  let matched
  for (let item in LAYOUT_DEFINITION) {
    if (matches(LAYOUT_DEFINITION[item])) {
      matched = item
      break
    }
  }
  return matched
}

const pagePlatform = () => {
  const { source, client, isApp } = urlInfo()
  if (isApp) {
    return (
      getDeviceFromClient(client) ||
      getDeviceFromSource(source) ||
      (matches(LAYOUT_DEFINITION["L"]) || matches(LAYOUT_DEFINITION["XL"])
        ? "web"
        : "msite")
    )
  }

  return (matches(LAYOUT_DEFINITION["L"]) || matches(LAYOUT_DEFINITION["XL"])) ? "web" : "msite"
}

export default {
  pagePlatform,
  currentDefinition
}
