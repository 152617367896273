import { Component } from "react"

class Toggle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
  }

  open = () => {
    this.setState({ isOpen: true })
  };

  close = () => {
    this.setState({ isOpen: false })
  };

  render() {
    const { isOpen } = this.state
    return this.props.children({
      isOpen,
      close: this.close,
      open: this.open
    })
  }
}

export default Toggle
